import produce from 'immer';
import { byId } from 'utils/utils';

export const REPORT_ACTION_TYPE = {
  GENERATE: 'GENERATE',
  CANCEL: 'CANCEL',
  RETRY: 'RETRY',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
  INIT: 'INIT',
  ADD: 'ADD',
};
export const ASYNC_REPORT_TYPE = {
  CLIENT_RETAILERS: 'CLIENT_RETAILERS',
  PLAN_OUTPUT: 'PLAN_OUTPUT',
  DELIVERY_EXECUTION: 'DELIVERY_EXECUTION_V2',
  DELIVERY_EXECUTION_CONCISE: 'DELIVERY_EXECUTION',
  DELIVERY_STATUS_LINE: 'DELIVERY_STATUS_LINE',
  DELIVERY_SUMMARY: 'DELIVERY_SUMMARY',
  UPI_REPORT: 'UPI_REPORT',
  INVOICE_STATUS: 'INVOICE_STATUS_V2',
  INVOICE_STATUS_CONCISE: 'INVOICE_STATUS',
  SALESMAN_REPORT: 'SALESMAN_REPORT',
  MANIFEST_REPORT: 'MANIFEST_REPORT',
  SKU_RETURN: 'SKU_RETURN',
  SALESMAN_PERFORMANCE_REPORT: 'SALESMAN_PERFORMANCE_REPORT',
  SALESMAN_CALL_COMPLIANCE: 'SALESMAN_CALL_COMPLIANCE',
  SALESMAN_CALL_PRODUCTIVITY: 'SALESMAN_CALL_PRODUCTIVITY',
  SALESMAN_EXCEPTION: 'SALESMAN_EXCEPTION',
  SALESMAN_TARGETS: 'SALESMAN_TARGETS',
  SALES_TARGET: 'SALES_TARGET',
  SKU_TARGET: 'SKU_TARGET',
  SALESMAN_SCORECARD: 'SALESMAN_SCORECARD',
  SALESMAN_MORNING: 'SALESMAN_MORNING',
  SALESMAN_EVENING: 'SALESMAN_EVENING',
  SALESMAN_BEATS: 'SALESMAN_BEATS',
  XDOCK_INCOMING: 'XDOCK_INCOMING',
  XDOCK_STATUS: 'XDOCK_STATUS',
  XDOCK_MISSING: 'XDOCK_MISSING',
  XDOCK_HU_MAPPING: 'XDOCK_HU_MAPPING',
  XDOCK_LAST_MILE: 'XDOCK_LAST_MILE',
  COVERAGE_EXPANSION: 'COVERAGE_EXPANSION',
  SUPERVISOR_REPORT: 'SUPERVISOR_REPORT',
  CASH_LEDGER_STATEMENT: 'CASH_LEDGER',
  CRM_NOTIFICATION: 'CRM_NOTIFICATION',
  CASHIER_SUMMARY: 'CASHIER_SUMMARY',
};

//TODO to be removed latter on
const demoClients = [1001124161131767, 4206228602515626];
export const REPORT_SECTIONS = [
  {
    sectionName: 'Customers Data',
    reportType: ASYNC_REPORT_TYPE.CLIENT_RETAILERS,
    showDateRange: false,
    permission: 'reports.retailersData',
  },
  {
    sectionName: 'Delivery Plan Output',
    reportType: ASYNC_REPORT_TYPE.PLAN_OUTPUT,
    showDateRange: true,
    //selectorKey:'leafNodes',
    permission: 'reports.planOutput',
  },
  {
    sectionName: 'Delivery status Detailed',
    reportType: ASYNC_REPORT_TYPE.DELIVERY_EXECUTION,
    showDateRange: true,
    permission: 'reports.deliveryStatusDetailed',
  },
  {
    sectionName: 'Delivery status',
    reportType: ASYNC_REPORT_TYPE.DELIVERY_EXECUTION_CONCISE,
    showDateRange: true,
    permission: 'reports.deliveryStatus',
  },
  {
    sectionName: 'Delivery status lines',
    reportType: ASYNC_REPORT_TYPE.DELIVERY_STATUS_LINE,
    showDateRange: true,
    permission: 'reports.deliveryStatusLines',
  },
  {
    sectionName: 'Invoice Status Detailed',
    reportType: ASYNC_REPORT_TYPE.INVOICE_STATUS,
    showDateRange: true,
    permission: 'reports.invoiceStatusDetailed',
  },
  {
    sectionName: 'Invoice Status',
    reportType: ASYNC_REPORT_TYPE.INVOICE_STATUS_CONCISE,
    showDateRange: true,
    permission: 'reports.invoiceStatus',
  },
  {
    sectionName: 'Delivery summary',
    reportType: ASYNC_REPORT_TYPE.DELIVERY_SUMMARY,
    showDateRange: true,
    permission: 'reports.deliverySummary',
  },
  {
    sectionName: 'UPI Report',
    reportType: ASYNC_REPORT_TYPE.UPI_REPORT,
    showDateRange: true,
    permission: 'reports.upi',
  },
  {
    sectionName: 'Salesman Report',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_REPORT,
    showDateRange: true,
    selectorKey: 'leafNodes',
    permission: 'reports.salesman',
  },
  {
    sectionName: 'Salesman Targets',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_TARGETS,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.salesmanTargets',
  },
  {
    sectionName: 'Salesman Beats',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_BEATS,
    showDateRange: false,
    permission: 'reports.salesmanBeats',
  },
  {
    sectionName: 'Sales Target Results',
    reportType: ASYNC_REPORT_TYPE.SALES_TARGET,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.salesTarget',
  },
  {
    sectionName: 'Sku Target Results',
    reportType: ASYNC_REPORT_TYPE.SKU_TARGET,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.skuTarget',
  },
  {
    sectionName: 'Salesman Exception Report',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_EXCEPTION,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.salesmanException',
  },
  {
    sectionName: 'Salesman Scorecard',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_SCORECARD,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.salesmanScorecard',
  },
  {
    sectionName: 'Salesman Morning Report',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_MORNING,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.salesmanMorning',
  },
  {
    sectionName: 'Salesman Evening Report',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_EVENING,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.salesmanEvening',
  },
  {
    sectionName: 'Supervisor Report',
    reportType: ASYNC_REPORT_TYPE.SUPERVISOR_REPORT,
    showDateRange: true,
    showNodeSelector: false,
    permission: 'reports.supervisor',
  },
  {
    sectionName: 'Manifest report',
    reportType: ASYNC_REPORT_TYPE.MANIFEST_REPORT,
    showDateRange: true,
    // maxDays: 1,
    permission: 'reports.manifest',
  },
  {
    sectionName: 'SKU Return report',
    reportType: ASYNC_REPORT_TYPE.SKU_RETURN,
    showDateRange: true,
    permission: 'reports.skuReturn',
  },
  {
    sectionName: 'Salesman Performance Summary Report',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_PERFORMANCE_REPORT,
    showDateRange: true,
    url:
      'https://storage.googleapis.com/misc.stackbox.xyz/Salesman%20Performance%20Report%20Summary.xlsx',
    clients: demoClients,
    permission: 'reports.salesmanPerformanceSummary',
  },
  {
    sectionName: 'Salesman Call Compliance',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_CALL_COMPLIANCE,
    showDateRange: true,
    url:
      'https://storage.googleapis.com/misc.stackbox.xyz/Salesman%20Call%20Compliance%20Report.xlsx',
    clients: demoClients,
    permission: 'reports.salesmanCallCompliance',
  },
  {
    sectionName: 'Salesman Call Productivity',
    reportType: ASYNC_REPORT_TYPE.SALESMAN_CALL_PRODUCTIVITY,
    showDateRange: true,
    url:
      'https://storage.googleapis.com/misc.stackbox.xyz/Salesman%20Call%20Productivity%20Report.xlsx',
    clients: demoClients,
    permission: 'reports.salesmanCallProductivity',
  },
  {
    sectionName: 'Xdock Incoming Report',
    reportType: ASYNC_REPORT_TYPE.XDOCK_INCOMING,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.xdockIncoming',
  },
  {
    sectionName: 'Xdock Status Report',
    reportType: ASYNC_REPORT_TYPE.XDOCK_STATUS,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.xdockStatus',
  },
  {
    sectionName: 'Xdock Missing report',
    reportType: ASYNC_REPORT_TYPE.XDOCK_MISSING,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.xdockMissing',
  },
  {
    sectionName: 'Xdock HU Mapping',
    reportType: ASYNC_REPORT_TYPE.XDOCK_HU_MAPPING,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.xdockHuMapping',
  },
  {
    sectionName: 'Xdock LMR Report',
    reportType: ASYNC_REPORT_TYPE.XDOCK_LAST_MILE,
    showDateRange: true,
    selectorKey: 'nodesTillFirstLevel',
    permission: 'reports.xdockLMR',
  },
  {
    sectionName: 'Coverage Expansion',
    reportType: ASYNC_REPORT_TYPE.COVERAGE_EXPANSION,
    showDateRange: true,
    selectorKey: 'leafNodes',
    permission: 'reports.coverageExpansion',
  },
  {
    sectionName: 'Cash Ledger Report',
    reportType: ASYNC_REPORT_TYPE.CASH_LEDGER_STATEMENT,
    showDateRange: true,
    selectorKey: 'leafNodes',
    permission: 'reports.cashLedger',
  },
  {
    sectionName: 'CRM Report',
    reportType: ASYNC_REPORT_TYPE.CRM_NOTIFICATION,
    showDateRange: true,
    permission: '',
  },
  {
    sectionName: 'Cashier Summary Report',
    reportType: ASYNC_REPORT_TYPE.CASHIER_SUMMARY,
    showDateRange: true,
    permission: '',
  },
];

export const ASYNC_REPORT_API = {
  getLatest: (hours) => `asyncReports/latest?hours=${hours}`,
  generate: ({ type, dateRange = [] }) =>
    dateRange.length > 0
      ? `asyncReports/${type}/generate?startDate=${dateRange[0]}&endDate=${dateRange[1]}`
      : `asyncReports/${type}/generate`,
  downloadReport: (taskId) => `/api/asyncReports/${taskId}/download`,
  cancel: (reportId) => `asyncReports/${reportId}/cancel`,
};

export const REPORT_DEFAULT = {
  canceled: false,
  status: 'created', //cancelled/created/progress/completed
  progress: 0, //0 -100
  updateCount: 0,
};

const reportReducer = (state, [action, data]) =>
  produce(state, (draft) => {
    switch (action) {
      case REPORT_ACTION_TYPE.INIT:
        data.forEach((item) => draft.push({ ...REPORT_DEFAULT, ...item }));
        draft.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case REPORT_ACTION_TYPE.ADD:
        draft.splice(0, 0, data);
        break;
      case REPORT_ACTION_TYPE.UPDATE_PROGRESS:
        data.forEach((item) => {
          const found = byId(draft, item.id);
          if (found) {
            found.updateCount += 1;
            Object.assign(found, item);
          }
          // else {
          //    console.log(item.id);
          // }
        });
        break;
      default:
        return state;
    }
  });

export default reportReducer;
